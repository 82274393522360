import { Component } from '@angular/core'
import { Contact } from './contact.model'

@Component({
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent {
  contacts = [
  
    new Contact(
      'niteshbhurtel@gmail.com',
      'mailto:niteshbhurtel@gmail.com',
      'email',
      'envelope',
    ),
    
    new Contact(
      '/nitesh-bhurtel-149003133',
      'https://www.linkedin.com/in/nitesh-bhurtel-149003133/',
      'linkedin',
      'linkedin',
    ),
    
    new Contact(
      '@niteshizcool',
      'https://twitter.com/niteshizcool',
      'twitter',
      'twitter',
    ),

    new Contact(
      '@nitesh_.007',
      'https://instagram.com/nitesh_.007',
      'instagram',
      'instagram',
    ),
      
    new Contact(
      '@niteshizcool',
      'https://facebook.com/niteshizcool',
      'facebook',
      'facebook',
    )
 
  ]
}
