import { Component, OnInit } from '@angular/core'
import { InfoField } from './infoField.model'
import { AboutService } from './about.service'


@Component({
  templateUrl: './about.component.html',
  providers: [AboutService],
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {
  works: InfoField[]
  education: InfoField[]
  languages: InfoField[]
  starredProjects: any[] = []

  constructor(
    aboutService: AboutService,

  ) {
    this.works = aboutService.getWorks()
    this.education = aboutService.getEducation()
    this.languages = aboutService.getLanguages()
  }

  ngOnInit() {
   
  }
}
