import { InfoField } from './infoField.model'
import { Injectable } from '@angular/core'

@Injectable()
export class AboutService {
  works: InfoField[] = [
    new InfoField(
      'Application Support Officer-Angular Developer',
      'Ministry of Finance, Government of Nepal, Financial Comptroller General Officer',
      'Aug 2021 - Present',
      'https://www.fcgo.gov.np/',
    ),
    new InfoField(
      'Software Developer',
      'Virtual IT solution',
      'Aug. 2020 - July 2021',
      '',
    ),
    new InfoField(
      'Project Manager',
      'nCloud Private Limited',
      'Aug 2019. 2017 - Feb 2020',
      'https://www.ncloud.com.np/',
    ),
    new InfoField(
      'Quality Assurance Engineer',
      'National Incubation and Research Center',
      'May 2018 - May 2019',
      'http://www.nirc.com.np/',
    )
    
  ]

  education: InfoField[] = [
    new InfoField(
      'Bachelor of Information Management',
      '',
      'Orchid International College(TU)',
      'https://www.oic.edu.np/',
    ),
    new InfoField(
      '+2 Mangement',
      '',
      'Global College of Management(HSEB)',
      'https://web.globalcollege.edu.np/',
    ),
    new InfoField(
      'School Leaving Certificate(GoN)',
      '',
      'Hilary High School',
      'http://www.hilaryenglishschool.edu.np/',
    ),
  ]

  languages: InfoField[] = [
    new InfoField('Nepali', 'Bilingual or native competence', ' ', ' '),
    new InfoField('English', 'Profesional competence', ' ', ' '),
  ]

  getEducation(): InfoField[] {
    return this.education
  }

  getWorks(): InfoField[] {
    return this.works
  }

  getLanguages(): InfoField[] {
    return this.languages
  }
}
