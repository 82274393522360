import { Component, OnInit } from '@angular/core'
import { ProjectsService } from './projects.service'

@Component({
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  providers: [ProjectsService],
})
export class ProjectsComponent implements OnInit {
  tabOption = 0

  githubProjects: any[] = []

  constructor(private projectsService: ProjectsService) {}

  public ngOnInit() {
   
  }
}
